<template>
  <div v-if="cityData.htmlBlock" class="html-block" v-html="cityData.htmlBlock" />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("data", ["cityData"])
  }
}
</script>

<style>

</style>