<template>
  <Popup :isShow="orderFormIsVisible" @close="close()">
    <div class="component--order-form-popup" :class="{ show: orderFormIsVisible }">
      <EntryForm :typeIsVisible="true" />
      <div class="ps">
        <h4>Прием заявок пн-пт с 08:30 до 17:00</h4>
        <h4 style="font-size: 17px;text-align:  center;">Мастера работают ежедневно с 08:00 до 17:00, кроме воскресенья.</h4>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Popup from '@/components/Popup'
import EntryForm from '@/components/EntryForm'
export default {
  components: { Popup, EntryForm },
  computed: {
    ...mapGetters("data", ["orderFormIsVisible"]),
    cityData() {
      return this.$store.state.data.cityData
    },
    citySelected() {
      return this.$store.state.data.citySelected
    },
  },
  methods: {
    ...mapActions("data", ["citySelect", "orderFormIsVisibleSet", "productTypeSelectedSet"]),
    close() {
      this.orderFormIsVisibleSet(false)
      this.productTypeSelectedSet(null)
    }
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--order-form-popup {
  position: fixed;
  top: calc(50% - 238px);
  max-height: 700px;
  height: fit-content;
  transition: 0.5s all;
  font-size: 1.4rem;
  line-height: 2rem;
  border-radius: 1.6rem;
  border: 0;
  @media screen and (max-width: $width) {
    width: 100%;
    left: -100%;
  }
  @media screen and (min-width: $width) {
    width: 500px;
    left: -500px;
  }
  &.show {
    @media screen and (max-width: $width) {
      left: 0%;
    }
    @media screen and (min-width: $width) {
      left: calc(50% - 200px);
    }
  }
  .ps {
    background: linear-gradient(45deg, #FFC001 0%, #FFC001 40%, #FFD861 40%);
    margin: 0px 20px;
    border-radius: 6px;
    @media screen and (min-width: $width) {
      width: calc(500px - 40px);
    }
    h4 {
      font-size: 19px;
      text-align: center;
      color: #31343e;
      margin: 0px;
      font-weight: normal;
    }
  }
}
</style>