import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home'
import store from './store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/:city',
    children: [
      {
        path: ':city',
        name: 'HomeCity',
        component: Home
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  history: true,
  routes
})

router.beforeEach((to, from, next) => {
  const dataAll = store.getters['data/cityDataAll'];
  const toPathArr = to.path.split('/');
  const toCity = toPathArr[1];
  const cityDefault = store.getters['data/cityDefault'];

  if (!dataAll[toCity]) {
    const to = '/' + cityDefault;
    store.dispatch('data/citySelect', to);
    next(to)
  } else {
    store.dispatch('data/citySelect', toCity);
    next()
  }
})

export default router