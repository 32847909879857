<template>
  <div class="component--product">
    <div class="title">{{ title }}</div>
    <img :src="imgSrc" alt="" />
    <div class="price">{{ price }}</div>
    <div class="buy" @click="buyCall()">{{ btnTitle }}</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    title: {
      type: String
    },
    imgSrc: {
      type: String
    },
    price: {
      type: String
    },
    btnTitle: {
      type: String,
      default: 'ЗАКАЗАТЬ'
    },
    btnUrl: {
      type: String
    }
  },
  methods: {
    ...mapActions("data", ["orderFormIsVisibleSet", "productTypeSelectedSet"]),
    buyCall() {
      if (this.btnUrl) {
        window.open(this.btnUrl, '_blank');
      } else {
        this.productTypeSelectedSet(this.title)
        this.orderFormIsVisibleSet(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.component--product {
  width: 298px;
  height: 478px;
  background: url(../assets/img/main/good_back.png) no-repeat center top 10%;
  background-size: 95% 85%;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    text-transform: uppercase;
    padding: 10px 10px;
    margin-bottom: 8px;
    height: 65px;
  }
  img {
    width: 90%;
    height: 52%;
    object-fit: contain;
  }
  .price {
    width: 100%;
    height: 49px;
    text-align: center;
    line-height: 49px;
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    background: url(../assets/img/main/price_back.png) no-repeat center;
  }
  .buy {
    width: 100%;
    height: 49px;
    background: url(../assets/img/main/buy.png) no-repeat center;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 49px;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>