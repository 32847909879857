<template>
  <div class="component--catalog">
    <h1>Cпектр предоставляемых&nbsp;услуг</h1>
    <h2>Коллективные заявки оговариваются индивидуально</h2>
    <div class="catalog">
      <div class="products">
        <Product
          v-for="(product, i) in cityData.catalog"
          :key="'product-' + i"
          :title="product.title"
          :imgSrc="`catalog/${product.img}`"
          :price="product.price"
          :btnTitle="product.btnTitle"
          :btnUrl="product.btnUrl"
        />
      </div>
      <div class="downloads">
        <a
          v-for="(download, i) in cityData.downloads"
          :key="i"
          target="_blank"
          :href="`/downloads/${download.fileName}`"
          class="price-download"
        >
          {{ download.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Product from '@/components/Product'
export default {
  components: { Product },
  computed: {
    cityData() {
      return this.$store.getters['data/cityData']
    }
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--catalog {
  h1 {
    font-weight: 600;
    color: #31343e;
    text-transform: uppercase;
    padding-top: 16px;
    @media screen and (max-width: $width) {
      font-size: 22px;
    }
    @media screen and (min-width: $width) {
      font-size: 31px;
    }
  }
  h2 {
    font-size: 21px;
    color: #f2212b;
    margin: 0px;
    text-transform: uppercase;
  }
  .catalog {
    display: flex;
    flex-direction: column;
    padding: 50px 10px 50px;
    background: url(../assets/img/main/back_3.png) repeat-x, url(../assets/img/main/back.png) repeat-x;
    @media screen and (max-width: $width) {
      background-size: 60% 100%, 60% 80%;
      background-position: 0% 380px, 0% -300px;
    }
    @media screen and (min-width: $width) {
      background-size: 10% 100%, 10% 100%;
      background-position: 0% 380px, 0% -120px;
    }
    .products {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      @media screen and (min-width: $width) {
        width: 950px;
        margin: auto;
      }
    }
    .downloads {
      display: flex;
      justify-content: center;
      align-items: center;
      .price-download {
        font-family: Roboto, Helvetica, Arial, sans-serif;
        min-width: calc(30% - 0px);
        display: table-cell;
        text-align: center;
        padding: 13px 12px;
        margin: 0px 20px;
        background-image: linear-gradient(to top, #fc8507, #ffa218);
        border: 1px solid transparent;
        border-top-color: #fc8507;
        border-bottom-color: #ffa218;
        font-weight: bold;
        text-decoration: none;
        outline: none;
        color: #fff;
        cursor: pointer;
        @media screen and (max-width: $width) {
        }
        @media screen and (min-width: $width) {
        }
      }
    }
  }
}
</style>