<template>
  <div class="component--documents">
    <h1>Наши разрешительные документы</h1>
    <split-carousel
      :autoplay="false"
      :display-amount="3"
      :height="400"
      :item-width="284"
    >
      <div slot="left-arrow" class="prev"></div>
      <split-carousel-item v-for="(sliderImg, i) in sliderImgs" :key="'slider-img-' + i">
        <imgDetail :previewSrc="sliderImg.previewSrc" :detailSrc="sliderImg.detailSrc" />
      </split-carousel-item>
      <div slot="right-arrow" class="next"></div>
    </split-carousel>
  </div>
</template>

<script>
import { SplitCarousel, SplitCarouselItem } from "vue-split-carousel";
import imgDetail from '@/components/imgDetail'
export default {
  components: {
    SplitCarousel,
    SplitCarouselItem,
    imgDetail
  },
  data() {
    return {
      sliderImgs: [
        { previewSrc: require('@/assets/img/carousel/1pr.jpg'), detailSrc: require('@/assets/img/carousel/originals/1.jpg') },
        { previewSrc: require('@/assets/img/carousel/6pr.jpg'), detailSrc: require('@/assets/img/carousel/originals/6.jpg') },
        { previewSrc: require('@/assets/img/carousel/7pr.jpg'), detailSrc: require('@/assets/img/carousel/originals/7.jpg') },
        { previewSrc: require('@/assets/img/carousel/3pr.jpg'), detailSrc: require('@/assets/img/carousel/originals/3.jpg') },
        { previewSrc: require('@/assets/img/carousel/4pr.jpg'), detailSrc: require('@/assets/img/carousel/originals/4.jpg') },
        { previewSrc: require('@/assets/img/carousel/5pr.jpg'), detailSrc: require('@/assets/img/carousel/originals/5.jpg') },
        { previewSrc: require('@/assets/img/carousel/2pr.jpg'), detailSrc: require('@/assets/img/carousel/originals/2.jpg') },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--documents {
  @media screen and (min-width: $width) {
    width: 70%;
    margin: auto;
  }
  &>h1 {
    padding: 40px 0 22px;
    margin: 0px;
    margin-bottom: 46px;
    font-size: 31px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    background: url(../assets/img/points/elips.png) no-repeat bottom;
  }
  .prev, .next {
    width: 26px;
    height: 38px;
    background: url(../assets/img/carousel/arrows.png) no-repeat;
  }
  .prev {
    background-position: 0px 0px;
  }
  .next {
    background-position: -26px 0px;
  }
}
</style>