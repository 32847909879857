<template>
  <div class="component--main-block-2">
    <h1>ЗАЧЕМ НУЖНА ПОВЕРКА СЧЕТЧИКОВ?</h1>
    <div class="bonus">
      <div class="info">
        Согласно Федеральному закону от 26.06.2008 №102-ФЗ «Об обеспечении единства измерений», а
также постановлению Правительства РФ от 06.05.2011 №354, потребитель обязан обеспечить
проведение поверок индивидуальных приборов учета холодной и горячей воды в сроки,
установленные технической документацией (паспортами) на приборы (ХВС – каждые 6 лет, ГВС –
каждые 4/6 года<br>
1.В случае истечения межповерочного интервала приборов учета: приборы считаются вышедшими
из строя и начисление по показаниям данного прибора прекращается;
<br>2.начисление платы за расчетный период выполняется по нормативу потребления,
соответствующего коммунального ресурса, с учетом зарегистрированных граждан и с
применением повышающего коэффициента равного 1,5 .<br>
3.перерасчет начисления платы за период работы ИПУ с истекшим сроком поверки
выполняться НЕ БУДЕТ.<br>
4.Расчет потребления коммунальных услуг, исходя из показаний ИПУ, возобновляется с даты<br>
проведения поверки приборов.
      </div>
      <div class="get-sale">
        <h2>Получи&nbsp;скидку&nbsp;10%<br>при&nbsp;заказе&nbsp;через&nbsp;сайт</h2>
        <a class="get-sale-btn" @click.prevent="orderFormIsVisibleSet(true)">
          Получить скидку
        </a>
        <Timer
          class="timer"
          :starttime="startTime"
          :endtime="endTime"
          trans='{
          "day":"д",
          "hours":"ч",
          "minutes":"м",
          "seconds":"с",
          "expired":"Event has been expired.",
          "running":"Till the end of event.",
          "upcoming":"Till start of event.",
          "status": {
            "expired":"Expired",
            "running":"Running",
            "upcoming":"Future"
          }}'
         ></Timer>
      </div>
    </div>
    <h1 style="margin: 0px;">ПАМЯТКА ДЛЯ ПОТРЕБИТЕЛЕЙ</h1>
    <div class="memo memo-1">С 1 января 2021г. вступил в силу Приказ Минпромторга России от 31.07.2020 N 2510 "Об утверждении порядка проведения поверки средств измерений, требований к знаку поверки и содержанию свидетельства о поверке", (Далее по тексту Приказ).
В соответствии с п. 21 настоящего Приказа, официальным подтверждением проведения поверки приборов учета холодной и горячей воды являются сведения, опубликованные в Федеральном информационном фонде по обеспечению единства измерений (ФИФ ОЕИ).
Аккредитованная организация, осуществляющая поверку счетчиков, обязана в срок не более 40 рабочих дней с даты проведения поверки средств измерений передать сведения в ФИФ ОЕИ.
Проверить информацию о поверенном счетчике холодной (горячей) воды) Вы можете с помощью информационного ресурса: <a href="https://fgis.gost.ru/fundmetrology/cm/results">fgis.gost.ru/fundmetrology/cm/results</a>
    </div>
    <div class="memo">ООО "РЭК" аккредитовано на право проведения поверки СИ. Сведения об аккредитации и подтверждении компетентности аккредитованного лица опубликованы на официальном сайте Росаккредитации в реестре аккредитованных лиц: <a href="https://pub.fsa.gov.ru/ral/view/32759/accredited-entity">pub.fsa.gov.ru/ral/view/32759/accredited-entity</a></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Timer from '@/components/Timer'
export default {
  components: { Timer },
  computed: {
    ...mapGetters("data", ["startTime", "timerMin"]),
    endTime() {
      return new Date(this.startTime.getTime() + (this.timerMin * 60 * 1000))
    }
  },
  methods: {
    ...mapActions("data", ["orderFormIsVisibleSet"])
  }
}
</script>

<style lang="scss" scoped>
  $width: 1324px;
  .component--main-block-2 {
    background: url(../assets/img/bonus/back.png) repeat-x;
    @media screen and (max-width: $width) {
      background-size: 60% 100%;
    }
    @media screen and (min-width: $width) {
      padding-bottom: 50px;
    }
    h1 {
      color: #31343e;
      text-transform: uppercase;
      padding: 40px 0 0 0;
      font-weight: 600;
      text-align: center;
      @media screen and (max-width: $width) {
        font-size: 22px;
      }
      @media screen and (min-width: $width) {
        font-size: 31px;
      }
    }
    .bonus {
      display: flex;
      @media screen and (max-width: $width) {
        background: url(../assets/img/bonus/bonus_m.png) no-repeat;
        background-position: center 0px;
        flex-direction: column;
        background-size: 90% 115%;
      }
      @media screen and (min-width: $width) {
        background: url(../assets/img/bonus/bonus.png) no-repeat center;
        background-size: 100% 100%;
        flex-direction: row;
        width: 1114px;
        margin: 40px auto 0;
      }
      .info {
        flex: 1;
        font-weight: 500;
        color: #fff;
        text-align: left;
        @media screen and (max-width: $width) {
          padding: 10px 45px;
          font-size: 12px;
        }
        @media screen and (min-width: $width) {
          padding: 10px 230px 10px 10px;
          font-size: 14px;
        }
      }
      .get-sale {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $width) {
          padding: 100px 0px 20px;
        }
        @media screen and (min-width: $width) {
          padding-right: 70px;
        }
        h2 {
          font-weight: 500;
          color: #ffffff;
          text-transform: uppercase;
          @media screen and (max-width: $width) {
            font-size: 22px;
          }
          @media screen and (min-width: $width) {
            font-size: 22px;
          }
        }
        .get-sale-btn {
          display: block;
          font-size: 19px;
          font-weight: 500;
          color: #fff;
          width: 344px;
          height: 51px;
          background: url(../assets/img/points/form_button.png) no-repeat center;
          text-transform: uppercase;
          text-align: center;
          text-decoration: none;
          line-height: 51px;
          cursor: pointer;
          @media screen and (max-width: $width) {
            transform: scale(0.8);
          }
        }
        .timer {
          margin-top: 20px;
        }
      }
    }
    .memo-1 {
      @media screen and (max-width: $width) {
        padding-bottom: 20px !important;
      }
    }
    .memo {
      text-align: left;
      @media screen and (max-width: $width) {
        width: calc(100% - 40px);
        padding: 10px 20px 40px;
      }
      @media screen and (min-width: $width) {
        width: calc(100% - 780px);
        margin: 40px 390px 0px;
        font-size: 19px;
      }
    }
  }
</style>