<template>
  <div class="component--popup">
    <div v-if="isShow" class="close" @click="$emit('close')"></div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.component--popup {
  .close {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba($color: black, $alpha: .7);
  }
}
</style>