<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@font-face {
  font-family: "PFDinTextCondPro";
  src: url(./assets/font/PFDinTextCondPro-Regular.ttf);
}
body {
  margin: 0px;
}
#app {
  font: 20px PFDinTextCondPro;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
