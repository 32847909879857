<template>
  <Popup :isShow="orderAcceptedIsVisible" @close="orderAcceptedIsVisibleSet(false)">
    <div class="component--order-accepted" :class="{ show: orderAcceptedIsVisible }">
      Спасибо за заявку, с вами скоро свяжутся!
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Popup from '@/components/Popup'
export default {
  components: { Popup },
  computed: {
    ...mapGetters("data", ["orderAcceptedIsVisible"]),
  },
  methods: {
    ...mapActions("data", ["orderAcceptedIsVisibleSet"]),
  }
}
</script>

<style lang="scss" scoped>
.component--order-accepted {
  visibility: hidden;
  opacity: 0;
  color: #68696B;
  width: 300px;
  height: 300px;
  left: calc(50% - 150px);
  top: calc(50% - 150px);
  position: fixed;
  background: #F2F5FA;
  border: 14px #01BFF9;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  &.show {
    visibility: visible;
    opacity: 1;
  }
}
</style>