
import axios from 'axios'
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async init({ dispatch }) {
      return await axios.get("/data.json").then(response => {
        if (typeof response.data !== 'object') {
          throw 'Повреждена структура data.json'
        }
        dispatch('data/init', response.data, { root: true })
      })
    },
    async sendMessage({ dispatch }, data) {
      return await axios({
        method: 'post',
        url: 'sendMessage.php',
        data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => dispatch('data/orderAcceptedIsVisibleSet', true, { root: true }))
        .catch(error => {
          console.log(error)
        });
    }
  },
  getters: {}
}