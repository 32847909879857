<template>
  <div :ref="'component--img-detail-' + _uid" class="component--img-detail">
    <img :src="previewSrc" alt="" width="284" height="370" @click="detailShow()" />
    <div
      :ref="'img-detail' + _uid"
      v-show="detailIsShow"
      class="detail"
    >
      <img :src="detailSrc" />
      <div class="close" @click="detailHidden()">✖</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    previewSrc: {
      type: String,
    },
    detailSrc: {
      type: String,
    }
  },
  data() {
    return {
      detailIsShow: false
    }
  },
  methods: {
    detailShow() {
      const detailEl = this.$refs['img-detail' + this._uid];
      document.body.appendChild(detailEl);
      this.detailIsShow = true
    },
    detailHidden() {
      const detailEl = this.$refs['img-detail' + this._uid];
      this.$refs['component--img-detail-' + this._uid].appendChild(detailEl);
      this.detailIsShow = false
    },
  }
}
</script>

<style lang="scss">
  .component--img-detail {
  }
  .detail {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background: white;
    img {
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .close {
      position: fixed;
      right: 20px;
      top: 20px;
      padding: 10px;
      background: rgba(229, 229, 229, 0.75);
      color: white;
      font-size: 30px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        background: rgba(207, 207, 207, 0.75);
      }
    }
  }
</style>