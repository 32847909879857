<template>
  <div class="component--city-alert" :class="{ hidden: !cityAlertIsVisible }">
    <p>Ваш город</p>
    <div class="city-name">{{ cityData.name }}?</div>
    <div class="buttons">
      <button class="accept" @click="accept()">Все верно</button>
      <button class="decline" @click="decline()">Сменить город</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("data", ["cityData", "cityAlertIsVisible"])
  },
  methods: {
    ...mapActions("data", ["cityAlertIsVisibleSet", "citySelectIsVisibleSet"]),
    accept() {
      this.cityAlertIsVisibleSet(false)
    },
    decline() {
      this.citySelectIsVisibleSet(true)
      this.cityAlertIsVisibleSet(false)
    }
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--city-alert {
  left: calc(50% - 176px);
  padding: 24px;
  position: fixed;
  text-align: center;
  width: 304px;
  z-index: 100;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 16px 24px 0 rgb(0 0 0 / 32%);
  transform-origin: left bottom;
  @media screen and (max-width: $width) {
    bottom: 90px;
  }
  @media screen and (min-width: $width) {
    bottom: 72px;
  }
  &.hidden {
    visibility: hidden;
    animation: city-alert-hidden 0.5s ease-out ;
  }
  p {
    font-size: 14px;
    margin: 0px;
    margin-bottom: 5px;
    font-family: Roboto,Helvetica,Arial,sans-serif;
  }
  .city-name {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 18px;
    font-family: Roboto,Helvetica,Arial,sans-serif;
  }
  .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .accept {
      height: 44px;
      line-height: 42px;
      min-width: calc(50% - 12px);
      padding: 0 12px;
      background-image: linear-gradient(to top, #fc8507, #ffa218);
      border: 1px solid transparent;
      border-top-color: #fc8507;
      border-bottom-color: #ffa218;
      font-weight: bold;
      text-decoration: none;
      outline: none;
      color: #fff;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      overflow: hidden;
      border-radius: 8px;
      font-size: 16px;
      animation-fill-mode: forwards;
    }
    .decline {
      min-width: calc(50% - 12px);
      padding: 0 12px;
      height: 44px;
      line-height: 42px;
      background: #fff;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      overflow: hidden;
      border-radius: 8px;
      color: #333;
    }
  }
}

@keyframes city-alert-hidden {
  0% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }

  99% {
    transform: scale(0.25);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: scale(0.25);
    opacity: 0;
    visibility: hidden;
  }
}
</style>