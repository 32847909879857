<template>
  <div class="component--header">
    <div class="logo logo-pc" />
    <div class="wrapper-1">
      <div class="wrapper-1-1">
        <h1 class="title">
          Поверка счетчиков воды
          <br>
          Мы поверяем, вы экономите
        </h1>
        <h3 class="subtitle" v-html="cityData.subtitle"></h3>
      </div>
      <div class="wrapper-1-2">
        <div class="logo logo-mobile"></div>
        <div class="wrapper-1-2-1">
          <a
            v-for="(phone, i) in cityData.phones"
            :key="i"
            class="link"
            :href="`javascript:window.location='tel:${phone.replace(/-/g, '')}';`"
          >
            {{ phone }}
          </a>
          <a
            class="city"
            href="#"
            @click.prevent="citySelectIsVisibleSet(true)"
          >
            {{ cityData.name }}
          </a>
          <img
            class="order-call"
            src="@/assets/img/header/button.png"
            @click="orderFormIsVisibleSet(true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("data", ["cityData"])
  },
  methods: {
    ...mapActions("data", ["citySelectIsVisibleSet", "orderFormIsVisibleSet"])
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--header {
  display: flex;
  @media screen and (max-width: $width) {
    flex-direction: column;
  }
  @media screen and (min-width: $width) {
    flex-direction: row;
  }
  // background: url(../assets/img/header/back.png);

  .logo {
    background: url(../assets/img/header/logo.png) no-repeat;
    background-color: #00345A;
    display: none;
  }
  @media screen and (max-width: $width) {
    .logo-mobile {
      display: block;
      background-size: auto 90%;
      background-position: 50% center;
      flex: 1;
    }
  }
  @media screen and (min-width: $width) {
    .logo-pc {
      display: block;
      background-size: 70%;
      background-position: 200px center;
      flex: 2;
    }
  }
  .wrapper-1 {
    display: flex;
    @media screen and (max-width: $width) {
      background: linear-gradient(-45deg, #003962 0%, #003962 50%, #00315B 50%);
      flex-direction: column;
    }
    @media screen and (min-width: $width) {
      background: linear-gradient(-45deg, #00315B 0%, #00315B 50%, #003962 50%);
      flex-direction: row;
    }
    flex: 4;
    .wrapper-1-1 {
      @media screen and (max-width: $width) {
        padding-top: 15px;
        padding-right: 10px;
        padding-left: 15px;
      }
      @media screen and (min-width: $width) {
        padding-left: 30px;
      }
      display: flex;
      flex: 1;
      flex-direction: column;
      .title {
        @media screen and (max-width: $width) {
          font-size: 26px;
        }
        @media screen and (min-width: $width) {
          font-size: 46px;
        }
        color: #ffffff;
        font-family: serif;
        text-shadow: 0px 0.3px 1px white;
        font-weight: 500;
        text-align: left;
        margin: 0px;
      }
      .subtitle {
        @media screen and (max-width: $width) {
          font-size: 24px;
        }
        @media screen and (min-width: $width) {
          font-size: 28px;
        }
        color: #ffc823;
        font-family: auto;
        font-weight: normal;
        text-align: left;
        margin: 6px 0px;
      }
    }
    .wrapper-1-2 {
      @media screen and (max-width: $width) {
        order: -1;
      }
      display: flex;
      flex: 1;
      .wrapper-1-2-1 {
        @media screen and (max-width: $width) {
          padding-top: 10px;
          padding-right: 10px;
        }
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 30px;
        .link, .city {
          font-weight: bold;
          color: #fff;
          text-decoration: none;
          text-indent: 25px;
          @media screen and (max-width: $width) {
            font-size: 24px;
          }
          @media screen and (min-width: $width) {
            font-size: 35px;
          }
          &:hover {
            color: #ffc823;
          }
        }
        .link {
          @media screen and (max-width: $width) {
            background: url('../assets/img/header/icons.png') no-repeat 0px 3px;
            background-size: 22px;
          }
          @media screen and (min-width: $width) {
            background: url('../assets/img/header/icons.png') no-repeat 0px 8px;
            background-size: 26px;
          }
        }
        .city {
          @media screen and (max-width: $width) {
            margin-top: 10px;
            background: url('../assets/img/header/gps.png') no-repeat 0px 0px;
            background-size: 16px;
          }

          @media screen and (min-width: $width) {
            background: url('../assets/img/header/gps.png') no-repeat 0px 0px;
            background-size: 20px;
          }
        }
        .order-call {
          cursor: pointer;
        }
      }
    }
  }
}

</style>