<template>
  <Popup :isShow="citySelectIsVisible" @close="citySelectIsVisibleSet(false)">
    <div class="component--city-select-popup" :class="{ show: citySelectIsVisible }">
      <div class="title">Выберите город</div>
      <div class="list">
        <ul>
          <li
            v-for="(data, cityId, i) in cityDataAll"
            :key="i"
            @click="citySelecting(cityId)"
            :class="{ selected: citySelected === cityId }"
          >
            {{ data.name }}
          </li>
        </ul>
      </div>
      <div class="close-x" @click="citySelectIsVisibleSet(false)">✖</div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Popup from '@/components/Popup'
export default {
  components: { Popup },
  computed: {
    ...mapGetters("data", ["citySelectIsVisible", "cityDataAll", "citySelected"]),
  },
  methods: {
    ...mapActions("data", ["citySelectIsVisibleSet"]),
    citySelecting(cityId) {
      this.citySelectIsVisibleSet(false)
      if (cityId !== this.citySelected) {
        this.$router.push('/' + cityId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--city-select-popup {
  position: fixed;
  transition: 0.5s all;
  background: #fff;
  font-size: 1.4rem;
  line-height: 2rem;
  @media screen and (max-width: $width) {
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0px;
  }
  @media screen and (min-width: $width) {
    left: -400px;
    top: calc(50% - 310px);
    width: 400px;
    max-height: 621px;
    height: fit-content;
    border-radius: 1.6rem;
  }
  &.show {
    @media screen and (max-width: $width) {
      left: 0px;
    }
    @media screen and (min-width: $width) {
      left: calc(50% - 200px);
    }
  }
  .title {
    font-weight: 700;
    color: #333;
    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-style: normal;
    font-size: 2.2rem;
    padding-top: 30px;
    text-align: left;
    padding-left: 45px;
    padding-bottom: 20px;
  }
  .list {
    text-align: left;
    height: calc(100% - 122px);
    ul {
      height: 100%;
      overflow: auto;
      padding: 0px;
      li {
        font-family: Roboto,Helvetica,Arial,sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
        line-height: 1;
        display: block;
        margin-bottom: 0;
        cursor: pointer;
        padding: 12px 50px;
        &:hover {
          background-color: #f5f5f6;
          color: #bd0f2c;
          font-weight: bold;
        }
        &.selected {
          background-color: #f5f5f6;
          font-weight: bold;
          color: brown !important;
        }
      }
    }
  }
  .close-x {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px;
    background: rgba(229, 229, 229, 0.75);
    color: white;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    @media screen and (max-width: $width) {
      border-radius: 0% 0% 0% 50%;
    }
    @media screen and (min-width: $width) {
      border-radius: 0% 50% 0% 50%;
    }
    &:hover {
      background: rgba(207, 207, 207, 0.75);
    }
  }
}
</style>