<template>
  <img class="component--main-block-7" src="@/assets/img/main/1.png" />
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--main-block-7 {
  margin: auto;
  @media screen and (max-width: $width) {
    width: 95%;
  }
  @media screen and (min-width: $width) {
    width: 1010px;
    padding-top: 80px;
  }
}
</style>