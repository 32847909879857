<template>
  <div class="component--main-block-contact-form">
    <ContactForm title="СЭКОНОМЬТЕ ДЕНЬГИ НА ЗАМЕНЕ СЧЕТЧИКА - ПОЗВОНИТЕ ИЛИ ОСТАВЬТЕ ЗАЯВКУ ПРЯМО СЕЙЧАС" />
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm'
export default {
  components: { ContactForm }
}
</script>

<style lang="scss" scoped>
.component--main-block-contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px 0px;
  background: url(../assets/img/main/back_2.jpg);
}
</style>