<template>
  <div class="component--footer">
    <div class="address">
      <h3
        v-for="(foterText, i) in cityData.footerTexts"
        :key="i"
        v-html="foterText"
      ></h3>
      <h3>аттестат аккредитации RA.RU 312309 выдан 11.10.2017.г</h3>
      <h3 class="company-name">ООО "РЭК"</h3>
    </div>
    <div class="contact">
      <h4>Прием заявок пн-пт с 08:30 до 17:00</h4>
      <h4>Мастера работают ежедневно с 08:00 до 17:00, кроме&nbsp;воскресенья.</h4>
      <div class="phones">
        <a
          v-for="(phone, i) in cityData.phones"
          :key="i"
          class="link"
          :href="`javascript:window.location='tel:${phone.replace(/-/g, '')}';`"
        >
          {{ phone.replace(/-/g, '-&nbsp;') }}
        </a>
      </div>
      <img
        class="order-call"
        src="@/assets/img/header/button.png"
        @click="orderFormIsVisibleSet(true)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  computed: {
    cityData() {
      return this.$store.getters['data/cityData']
    }
  },
  methods: {
    ...mapActions("data", ["orderFormIsVisibleSet"])
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--footer {
  display: flex;
  background: linear-gradient(-45deg, #003962 0%, #003962 50%, #00315B 50%);
  padding-top: 22px;
  @media screen and (max-width: $width) {
    flex-direction: column;
  }
  @media screen and (min-width: $width) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
  }
  .address {
    @media screen and (max-width: $width) {
      text-align: center;
      padding-bottom: 10px;
    }
    @media screen and (min-width: $width) {
      text-align: left;
    }
    h3 {
      color: white;
      font-weight: 500;
      margin: 0px;
      text-transform: uppercase;
      font-size: 16px;
    }
    .company-name {
      color: #4577a5;
      font-size: 21px;
    }
  }
  .contact {
    text-align: center;
    h4 {
      color: #ffc200;
      font-weight: 500;
      margin: 0px;
      text-transform: uppercase;
      &:first-of-type {
        font-size: 19px;
      }
      &:last-of-type {
        font-size: 13px;
      }
    }
    .phones {
      .link {
        color: #fff;
        font-weight: 500;
        text-decoration: none;
        text-indent: 30px;
        padding-left: 26px;
        background: url('../assets/img/header/icons.png') no-repeat 0px 0px;
        background-size: 20px;
        @media screen and (max-width: $width) {
          font-size: 21px;
        }
        @media screen and (min-width: $width) {
          font-size: 21px;
        }
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
    .order-call {
      padding: 10px 0px;
      cursor: pointer;
    }
  }
}
</style>