import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/ecomment.css'
import './assets/js/ecomment.js'
import './assets/js/jquery.countdown.js'

Vue.config.productionTip = false;

store.dispatch('app/init').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})