<template>
  <div class="component--contact-form">
    <h1 v-if="title.length">{{ title }}</h1>
    <p v-if="subtitle.length">{{ subtitle }}</p>
    <form ref="form">
      <input :class="{error: errors.name}" name="name" type="text" placeholder="ИМЯ" id="main_name">
      <input :class="{error: errors.phone}" name="phone" type="text" placeholder="НОМЕР ТЕЛЕФОНА" id="main_telephone">
      <input :class="{error: errors.email}" name="email" type="text" placeholder="КОНТАКТНЫЙ E-MAIL" id="main_email">
      <input v-show="false" :value="cityData.name" name="city" type="text" id="main_city">
      <input type="submit" value="ОТПРАВИТЬ" class="submit" @click.prevent="submit()">
    </form>
    <div v-html="ps"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      errors: {
        name: false,
        phone: false,
        email: false,
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    ps: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters("data", ["cityData"])
  },
  methods: {
    ...mapActions("app", ["sendMessage"]),
    submit() {
      const form = this.$refs.form;
      const formData = new FormData(form);
      const phone = formData.get('phone');

      const nameError = !formData.get('name').length
      let phoneError = phone.length < 11 || phone.length > 12

      if (phone.length === 11) {
        phoneError = !(Array.from(phone)[0] == 8)
      } else if (phone.length === 12) {
        phoneError = !phone.includes('+7')
      }
      // const email = formData.get('email');
      // const emailError = !email.length;
      const emailError = false;

      this.errors.name = nameError
      this.errors.phone = phoneError
      this.errors.email = emailError

      if (!nameError && !phoneError && !emailError) {
        this.sendMessage(formData).then(() => form.reset())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--contact-form {
  background: url(../assets/img/main/form_back.png) no-repeat;
  padding: 18px 0px;
  margin: 5px auto;
  @media screen and (max-width: $width) {
    background-size: 100% 120%;
    padding-bottom: 40px;
    width: 95%;
  }
  @media screen and (min-width: $width) {
    background-size: 120% 100%;
    width: 960px;
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin: 0px;
  }
  p {
    color: #ffc200;
    text-align: center;
    margin-bottom: 17px;
    margin-top: 0px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
      height: 35px;
      border: none;
      margin: 5px 0px;
      text-align: center;
      font: 18px PFDinTextCondPro;
      @media screen and (max-width: $width) {
        background-size: 100% 90%;
        width: 90%;
      }
      @media screen and (min-width: $width) {
        background-size: 100%;
        width: 710px;
      }
      &.error {
        background: #ff4a4a;
      }
    }
    .submit {
      width: 234px;
      height: 50px;
      margin-top: 26px;
      background: url(../assets/img/main/form_button.png) no-repeat center;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1.5px;
      text-shadow: 0px 1.3px 1px white;
    }
  }
}
</style>