<template>
  <div class="component--main-block-6">
    <h1>Как мы работаем?</h1>
    <ul>
      <li><div class="num">1</div><span>Вы оставляете заявку.</span> <br>У нас на сайте или по телефонам: {{ cityData.phones.join(' / ') }}.</li>
      <li><div class="num">2</div><span>Бесплатная консультация.</span> <br>Консультация по телефону абсолютно бесплатно.</li>
      <li><div class="num">3</div><span>Выполнение работ.</span> <br>Выполнение работы в заранее согласованное и удобное для Вас время.</li>
      <li><div class="num">4</div><span>Принятие работы.</span> <br>Вы проверяете качество выполненных работ, мы предоставляем полный комплект необходимых документов.</li>
      <li><div class="num">5</div><span>Оплата.</span> <br>Оплачиваете, согласно условиям оплата мастеру, после выполнения работ.</li>
      <li v-for="(point, i) in cityData.points" :key="i"><div class="num">{{ 6 + i }}</div>{{ point }}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("data", ["cityData"])
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--main-block-6 {
  h1 {
    margin: 0px;
    padding: 58px 0 22px;
    margin-bottom: 46px;
    font-size: 31px;
    font-weight: 500;
    background: url(../assets/img/points/elips.png) no-repeat bottom;
  }
  ul {
    list-style: none;
    @media screen and (min-width: $width) {
      padding-left: 15%;
    }
    span {
      font-weight: 500;
    }
  }
  li {
    position: relative;
    font-size: 18px;
    text-align: left;
    padding-left: 60px;
    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    min-height: 50px;
    margin-bottom: 15px;
    cursor: pointer;
    @media screen and (max-width: $width) {
    }
    @media screen and (min-width: $width) {
    }
    &:hover {
      color: #bd0f2c;
      font-weight: bold;
    }
    .num {
      position: absolute;
      width: 38px;
      height: 38px;
      left: 0px;
      top: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'PFDinTextCondPro';
      font-weight: 600;
      border-radius: 50%;
      border: 3px solid;
    }
  }
}
</style>