<template>
  <div class="main-block-1">
    <h2 v-html="cityData.startInfo"></h2>
    <EntryForm class="form" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EntryForm from './EntryForm'
export default {
  components: { EntryForm },
  computed: {
    ...mapGetters("data", ["cityData"]),
  }
}
</script>

<style lang="scss" scoped>
  $width: 1324px;
  .main-block-1 {
    display: flex;
    justify-content: end;
    background: url(../assets/img/main/cam.png) no-repeat;
    @media screen and (max-width: $width) {
      flex-direction: column;
      background-size: 50%;
    }
    @media screen and (min-width: $width) {
      flex-direction: row;
      margin: 80px 310px;
    }
    h2 {
      @media screen and (max-width: $width) {
        margin-right: 20px;
        background: rgba(255,255,255,0.5);
        font-size: 17px;
      }
      @media screen and (min-width: $width) {
        font-size: 19px;
      }
      color: #24262f;
      font-weight: bold;
      text-transform: uppercase;
      text-align: right;
      margin-top: 20px;
    }

    @media screen and (max-width: $width) {
      .form {
        width: 100%;
      }
    }
    @media screen and (min-width: $width) {
    }
  }
</style>