<template>
  <div class="component--reviews">
    <h1>Отзывы</h1>
    <div ref="wrapper" class="wrapper"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    // eslint-disable-next-line no-undef
    window.$(this.$refs.wrapper).ecomment()
    // console.log($(this.$refs.wrapper).ecomment);
  }
}
</script>

<style lang="scss">
$width: 1324px;
.component--reviews {
  margin: auto;
  @media screen and (max-width: $width) {
    width: 100%;
    .ecomment_form dd {
      padding-left: 0px !important;
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      &:last-of-type {
        justify-content: center !important;
      }
      input {
        margin-left: 0px !important;
      }
    }
  }
  @media screen and (min-width: $width) {
    width: 950px;
    .ecomment_form dd {
      text-align: left;
    }
  }
  .wrapper {
    margin: 0 auto;
  }
}
</style>